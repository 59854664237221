// 参考ページ
// https://github.com/algolia/instantsearch.js/blob/61ad247c252a42743efa35d0e32d1802119e53a2/stories/panel.stories.ts#L58

import algoliasearchApiInstance from "../algoliasearch/api";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let isExist = document.querySelector("#js-filter-search");

  if (isExist == null) {
    return;
  }

  const search = instantsearch({
    indexName: algoliasearchApiInstance.getBoardIndexName(),
    searchClient: algoliasearchApiInstance.getClient(),
    insights: true,
  });

  search.addWidget(
    instantsearch.widgets.analytics({
      pushFunction(formattedParameters, state, results) {
        gtag('config', 'G-ZVDPNNBLJT', {
          'page_location': location.href,
          'page_referrer': document.referrer,
        });

      },
      triggerOnUIInteraction: true,
      pushInitialSearch: true,
      pushPagination: true
    })
  );

  search.addWidget(
    instantsearch.widgets.configure({
      hitsPerPage: 50,
      clickAnalytics: true
    })
  );

  search.addWidget(
    instantsearch.widgets.clearRefinements({
      container: document.querySelector(".filters-clear-refinements"),
      templates: {
        resetLabel: "リセット"
      }
    })
  );

  search.addWidget(
    instantsearch.widgets.searchBox({
      container: document.querySelector(".filters-search-box"),
      placeholder: "作品・著者・キャラで探す",
      showLoadingIndicator: false,
      showReset: false,
      showSubmit: false
    })
  );

  const sortByCategories = (a, b) => {
    const fixedOrder = [
      {
        order_id: 1,
        name: "少年マンガ"
      },
      {
        order_id: 2,
        name: "少女マンガ"
      },
      {
        order_id: 3,
        name: "青年マンガ"
      },
      {
        order_id: 4,
        name: "女性マンガ"
      },
      {
        order_id: 5,
        name: "ボーイズラブコミック"
      },
      {
        order_id: 6,
        name: "ティーンズラブコミック"
      },
      {
        order_id: 7,
        name: "レディースコミック"
      },
      {
        order_id: 8,
        name: "ハーレクインコミック"
      },
      {
        order_id: 9,
        name: "男性マンガ雑誌"
      },
      {
        order_id: 10,
        name: "女性マンガ雑誌"
      }
    ];
    const target_a = fixedOrder.find(function (element) {
      return element.name === a.name;
    });

    const target_b = fixedOrder.find(function (element) {
      return element.name === b.name;
    });

    if (target_a === undefined || target_b === undefined) {
      return 0;
    }

    return target_a.order_id - target_b.order_id;
  };

  const categoryNamesListWithPanel = instantsearch.widgets.panel({
    collapsed: options => {
      return options && options.state;
    },
    templates: {
      header({ state }) {
        const item = state.facetsRefinements.category_name;
        let length = item?.length ?? 0;

        return `<div class="title"><div class="text">🚻 カテゴリ</div> ${length == 0
          ? ""
          : `<div class="count">${length}件選択中</div>`
          }</div>`;
      },
      collapseButtonText: ({ collapsed }) => getCollapseIcon(collapsed)
    }
  })(instantsearch.widgets.refinementList);

  search.addWidget(
    categoryNamesListWithPanel({
      container: document.querySelector(".filters-category-names"),
      attribute: "category_name",
      operator: "and",
      sortBy: sortByCategories
    })
  );

  const awardNamesListWithPanel = instantsearch.widgets.panel({
    collapsed: options => {
      return options && options.state;
    },
    templates: {
      header({ state }) {
        const item = state.facetsRefinements.award_names;
        let length = item?.length ?? 0;

        return `<div class="title"><div class="text">🎖️ マンガ賞</div> ${length == 0
          ? ""
          : `<div class="count">${length}件選択中</div>`
          }</div>`;
      },
      collapseButtonText: ({ collapsed }) => getCollapseIcon(collapsed)
    }
  })(instantsearch.widgets.refinementList);

  search.addWidget(
    awardNamesListWithPanel({
      container: document.querySelector(".filters-award-names"),
      attribute: "award_names",
      limit: 20,
      operator: "and",
      sortBy: ["isRefined", "count:desc", "id:asc"]
    })
  );

  const tagNamesListWithPanel = instantsearch.widgets.panel({
    collapsed: options => {
      return options && options.state;
    },
    templates: {
      header({ state }) {
        const item = state.facetsRefinements._tags;
        let length = item?.length ?? 0;

        return `<div class="title"><div class="text">🔠 ジャンル</div> ${length == 0
          ? ""
          : `<div class="count">${length}件選択中</div>`
          }</div>`;
      },
      collapseButtonText: ({ collapsed }) => getCollapseIcon(collapsed)
    }
  })(instantsearch.widgets.refinementList);

  search.addWidget(
    tagNamesListWithPanel({
      container: document.querySelector(".filters-tag-names"),
      attribute: "_tags",
      limit: 15,
      operator: "and",
      sortBy: ["count:desc", "name:asc"]
    })
  );

  const releaseYearRangeListWithPanel = instantsearch.widgets.panel({
    collapsed: options => {
      return options && options.state;
    },
    templates: {
      header({ state }) {
        const item = state.facetsRefinements.release_year_range;
        let length = item?.length ?? 0;

        return `<div class="title"><div class="text">🕰️ 年代</div> ${length == 0
          ? ""
          : `<div class="count">${length}件選択中</div>`
          }</div>`;
      },
      collapseButtonText: ({ collapsed }) => getCollapseIcon(collapsed)
    }
  })(instantsearch.widgets.refinementList);

  search.addWidget(
    releaseYearRangeListWithPanel({
      container: document.querySelector(".filters-release-year-range"),
      attribute: "release_year_range",
      showMoreLimit: 20,
      operator: "and",
      sortBy: ["name:desc", "isRefined", "count:desc"]
    })
  );

  search.addWidget(
    instantsearch.widgets.stats({
      container: document.querySelector(".filters-stats"),
      templates: {
        text: `
                        <div class="count">{{nbHits}}</div><div class="unit">件</div>
                      `
      }
    })
  );

  search.addWidget(
    instantsearch.widgets.stats({
      container: document.querySelector(".filter-search-apply"),
      templates: {
        text: `
                        <div class="count">絞り込み({{nbHits}})</div>
                      `
      }
    })
  );

  const mediaTypesListWithPanel = instantsearch.widgets.panel({
    collapsed: options => {
      return options && options.state;
    },
    templates: {
      header({ state }) {
        const item = state.facetsRefinements.media_type_names;
        let length = item?.length ?? 0;

        return `<div class="title"><div class="text">🎬 メディア化</div> ${length == 0
          ? ""
          : `<div class="count">${length}件選択中</div>`
          }</div>`;
      },
      collapseButtonText: ({ collapsed }) => getCollapseIcon(collapsed)
    }
  })(instantsearch.widgets.refinementList);

  search.addWidget(
    mediaTypesListWithPanel({
      container: document.querySelector(".filters-media-type-names"),
      attribute: "media_type_names",
      sortBy: ["count:desc", "name:asc"]
    })
  );

  search.addWidget(
    instantsearch.widgets.toggleRefinement({
      container: document.querySelector(".filters-completed"),
      attribute: "is_completed_book",
      templates: {
        labelText({ onFacetValue }) {
          return `完結 ${onFacetValue.count == null
            ? ""
            : `<span class="count">${onFacetValue.count}</span>`
            }`;
        }
      }
    })
  );
  search.addWidget(
    instantsearch.widgets.toggleRefinement({
      container: document.querySelector(".filters-yomikiri"),
      attribute: "is_yomikiri",
      templates: {
        labelText({ onFacetValue }) {
          return `読切 ${onFacetValue.count == null
            ? ""
            : `<span class="count">${onFacetValue.count}</span>`
            }`;
        }
      }
    })
  );

  search.addWidget(
    instantsearch.widgets.toggleRefinement({
      container: document.querySelector(".filters-new-series"),
      attribute: "is_new_book",
      templates: {
        labelText({ onFacetValue }) {
          return `新連載 ${onFacetValue.count == null
            ? ""
            : `<span class="count">${onFacetValue.count}</span>`
            }`;
        }
      }
    })
  );

  search.addWidget(
    instantsearch.widgets.toggleRefinement({
      container: document.querySelector(".filters-free-campaigns"),
      attribute: "has_free_campaigns",
      templates: {
        labelText({ onFacetValue }) {
          return `無料で読める ${onFacetValue.count == null
            ? ""
            : `<span class="count">${onFacetValue.count}</span>`
            }`;
        }
      }
    })
  );

  search.addWidget(
    instantsearch.widgets.toggleRefinement({
      container: document.querySelector(".filters-sale-campaigns"),
      attribute: "has_sale_campaigns",
      templates: {
        labelText({ onFacetValue }) {
          return `セール中 ${onFacetValue.count == null
            ? ""
            : `<span class="count">${onFacetValue.count}</span>`
            }`;
        }
      }
    })
  );

  search.addWidget(
    instantsearch.widgets.toggleRefinement({
      container: document.querySelector(".filters-kindle-unlimited"),
      attribute: "has_kindle_unlimited",
      templates: {
        labelText({ onFacetValue }) {
          return `Kindle Unlimited ${onFacetValue.count == null
            ? ""
            : `<span class="count">${onFacetValue.count}</span>`
            }`;
        }
      }
    })
  );


  search.addWidget(
    instantsearch.widgets.toggleRefinement({
      container: document.querySelector(".filters-web-manga"),
      attribute: "has_web_manga",
      templates: {
        labelText({ onFacetValue }) {
          return `Webマンガ ${onFacetValue.count == null
            ? ""
            : `<span class="count">${onFacetValue.count}</span>`
            }`;
        }
      }
    })
  );

  search.addWidget(
    instantsearch.widgets.toggleRefinement({
      container: document.querySelector(".filters-kindle"),
      attribute: "has_kindle",
      templates: {
        labelText({ onFacetValue }) {
          return `Kindle ${onFacetValue.count == null
            ? ""
            : `<span class="count">${onFacetValue.count}</span>`
            }`;
        }
      }
    })
  );

  const keywordTagNamesListWithPanel = instantsearch.widgets.panel({
    collapsed: options => {
      return options && options.state;
    },
    templates: {
      header({ state }) {
        const item = state.disjunctiveFacetsRefinements.keyword_tag_names;
        let length = item?.length ?? 0;

        return `<div class="title"><div class="text">🏷 タグ</div> ${length == 0
          ? ""
          : `<div class="count">${length}件選択中</div>`
          }</div>`;
      },
      collapseButtonText: ({ collapsed }) => getCollapseIcon(collapsed)
    }
  })(instantsearch.widgets.refinementList);

  search.addWidget(
    keywordTagNamesListWithPanel({
      container: document.querySelector(".filters-keyword-tag-names"),
      searchable: true,
      showMoreLimit: 20,
      searchablePlaceholder: "タグを検索",
      searchableIsAlwaysActive: false,
      templates: {
        searchableNoResults: ""
      },
      limit: 5,
      attribute: "keyword_tag_names"
    })
  );

  const emotionalTagNamesListWithPanel = instantsearch.widgets.panel({
    collapsed: options => {
      return options && options.state;
    },
    templates: {
      header({ state }) {
        const item = state.disjunctiveFacetsRefinements.emotional_tag_names;
        let length = item?.length ?? 0;

        return `<div class="title"><div class="text">😊 感情タグ</div> ${length == 0
          ? ""
          : `<div class="count">${length}件選択中</div>`
          }</div>`;
      },
      collapseButtonText: ({ collapsed }) => getCollapseIcon(collapsed)
    }
  })(instantsearch.widgets.refinementList);

  search.addWidget(
    emotionalTagNamesListWithPanel({
      container: document.querySelector(".filters-emotional-tag-names"),
      operator: "and",
      limit: 5,
      attribute: "emotional_tag_names"
    })
  );

  const publisherNamesListWithPanel = instantsearch.widgets.panel({
    collapsed: options => {
      return options && options.state;
    },
    templates: {
      header({ state }) {
        const item = state.disjunctiveFacetsRefinements.publisher_name;
        let length = item?.length ?? 0;

        return `<div class="title"><div class="text">🏢 出版社</div> ${length == 0
          ? ""
          : `<div class="count">${length}件選択中</div>`
          }</div>`;
      },
      collapseButtonText: ({ collapsed }) => getCollapseIcon(collapsed)
    }
  })(instantsearch.widgets.refinementList);

  search.addWidget(
    publisherNamesListWithPanel({
      container: document.querySelector(".filters-publisher-names"),
      searchable: true,
      showMoreLimit: 20,
      searchablePlaceholder: "出版社を検索",
      searchableIsAlwaysActive: false,
      templates: {
        searchableNoResults: ""
      },
      limit: 5,
      attribute: "publisher_name"
    })
  );

  const releaseYearListWithPanel = instantsearch.widgets.panel({
    collapsed: options => {
      return options && options.state;
    },
    templates: {
      header({ state }) {
        const item = state.disjunctiveFacetsRefinements.release_year;
        let length = item?.length ?? 0;

        return `<div class="title"><div class="text">🗓️ 出版年</div> ${length == 0
          ? ""
          : `<div class="count">${length}件選択中</div>`
          }</div>`;
      },
      collapseButtonText: ({ collapsed }) => getCollapseIcon(collapsed)
    }
  })(instantsearch.widgets.refinementList);

  search.addWidget(
    releaseYearListWithPanel({
      container: document.querySelector(".filters-release-year"),
      searchable: true,
      showMoreLimit: 20,
      searchablePlaceholder: "出版年を検索",
      searchableIsAlwaysActive: false,
      templates: {
        searchableNoResults: ""
      },
      limit: 10,
      attribute: "release_year_text",
      sortBy: ["isRefined", "name:desc"]
    })
  );

  // Create the render function
  const renderRatingMenu = (renderOptions, isFirstRender) => {
    const { items, refine, createURL, widgetParams } = renderOptions;

    if (isFirstRender) {
      const ulElement = document.createElement("ul");
      ulElement.classList.add("ais-RatingMenu-list");
      widgetParams.container.appendChild(ulElement);

      return;
    }

    widgetParams.container.querySelector("ul").innerHTML = items
      .map(
        item =>
          `<li class="ais-RatingMenu-item">
            <a
              class="ais-RatingMenu-link"
              href="${createURL(item.value)}"
              data-value="${item.value}"
              style="font-weight: ${item.isRefined ? "bold" : ""}"
            >
              ${item.stars.map(isFilled => (isFilled ? "⭐" : "☆")).join("")}
              <span class="ais-RatingMenu-label">${item.value}以上</span>
              <span class="ais-RatingMenu-count">${item.count}</span>
            </a>
          </li>`
      )
      .join("");

    [...widgetParams.container.querySelectorAll("a")].forEach(element => {
      element.addEventListener("click", event => {
        event.preventDefault();
        refine(event.currentTarget.dataset.value);
      });
    });
  };

  // Create the custom widget
  const customRatingMenu = instantsearch.connectors.connectRatingMenu(
    renderRatingMenu
  );

  const ratingsWithPanel = instantsearch.widgets.panel({
    collapsed: options => {
      return options && options.state;
    },
    templates: {
      header({ state }) {
        const length = Object.keys(state.numericRefinements.favorite_rating_average).length

        return `<div class="title"><div class="text">⭐️ お気に入り度</div> ${length == 0
          ? ""
          : `<div class="count">${length}件選択中</div>`
          }</div>`;
      },
      collapseButtonText: ({ collapsed }) => getCollapseIcon(collapsed)
    }
  })(customRatingMenu);

  search.addWidget(
    ratingsWithPanel({
      container: document.querySelector(".filters-ratings"),
      attribute: "favorite_rating_average"
    })
  );

  const labelNamesListWithPanel = instantsearch.widgets.panel({
    collapsed: options => {
      return options && options.state;
    },
    templates: {
      header({ state }) {
        const item = state.disjunctiveFacetsRefinements.label_name;
        let length = item?.length ?? 0;

        return `<div class="title"><div class="text">📚 レーベル</div> ${length == 0
          ? ""
          : `<div class="count">${length}件選択中</div>`
          }</div>`;
      },
      collapseButtonText: ({ collapsed }) => getCollapseIcon(collapsed)
    }
  })(instantsearch.widgets.refinementList);

  search.addWidget(
    labelNamesListWithPanel({
      container: document.querySelector(".filters-label-names"),
      searchable: true,
      showMoreLimit: 20,
      searchablePlaceholder: "レーベルを検索",
      searchableIsAlwaysActive: false,
      templates: {
        searchableNoResults: ""
      },
      limit: 5,
      attribute: "label_name"
    })
  );

  const booksCountListWithPanel = instantsearch.widgets.panel({
    collapsed: options => {
      return options && options.state;
    },
    templates: {
      header({ state }) {
        const item = state.disjunctiveFacetsRefinements.books_count;
        let length = item?.length ?? 0;

        return `<div class="title"><div class="text">1️⃣ 巻数</div> ${length == 0
          ? ""
          : `<div class="count">${length}件選択中</div>`
          }</div>`;
      },
      collapseButtonText: ({ collapsed }) => getCollapseIcon(collapsed)
    }
  })(instantsearch.widgets.refinementList);

  search.addWidget(
    booksCountListWithPanel({
      container: document.querySelector(".filters-books-count"),
      searchable: true,
      showMoreLimit: 20,
      searchablePlaceholder: "巻数を検索",
      searchableIsAlwaysActive: false,
      templates: {
        searchableNoResults: ""
      },
      limit: 5,
      attribute: "books_count_text"
    })
  );


  const labelMap = {
    is_completed_book: "完結作品のみ",
    is_yomikiri: "読切のみ",
    is_new_book: "新連載のみ",
    has_free_campaigns: "無料",
    has_sale_campaigns: "セール",
    has_kindle_unlimited: "Kindle Unlimited",
    has_web_manga: "Webマンガ",
    has_kindle: "Kindle",
    favorite_rating_average: {
      '≤ 5': "⭐5以下",
      '≥ 4': "⭐4以上",
      '≥ 3': "⭐3以上",
      '≥ 2': "⭐2以上",
      '≥ 1': "⭐1以上"
    }
  };

  const getLabel = (refinement) => {
    const attributeLabels = labelMap[refinement.attribute];
    if (typeof attributeLabels === 'object') {
      return attributeLabels[refinement.label] || refinement.label;
    }
    return attributeLabels || refinement.label;
  };

  const createDataAttribtues = refinement =>
    Object.keys(refinement)
      .map(key => `data-${key}="${refinement[key]}"`)
      .join(" ");

  const renderListItem = item => `
      <li>
        <div class="current-refinement">
            <ul>
              ${item.refinements
      .map(
        refinement =>
          `
          <li>
            <span class='item'>
              <span class='label'>
                ${getLabel(refinement)}
              </span>
              <button ${createDataAttribtues(refinement)}>
                <img src='https://res.cloudinary.com/hstqcxa7w/image/upload/common/icons/close.svg'>
              </button>
            </span>
          </li>
            `
      )
      .join("")}
            </ul>
        </div>
      </li>
    `;

  const renderCurrentRefinements = (renderOptions, isFirstRender) => {
    const { items, refine, widgetParams } = renderOptions;

    widgetParams.container.innerHTML = `
            <div class="filter-search-current-refinement-module">
                <ul>
                  ${items.map(renderListItem).join("")}
                </ul>
            </div>
          `;
    [...widgetParams.container.querySelectorAll("button")].forEach(element => {
      element.addEventListener("click", event => {
        const item = Object.keys(event.currentTarget.dataset).reduce(
          (acc, key) => ({
            ...acc,
            [key]: event.currentTarget.dataset[key]
          }),
          {}
        );

        refine(item);
      });
    });
  };

  // Create the custom widget
  const customCurrentRefinements = instantsearch.connectors.connectCurrentRefinements(
    renderCurrentRefinements
  );

  // Instantiate the custom widget
  search.addWidgets([
    customCurrentRefinements({
      container: document.querySelector(".filters-current-refinements")
    })
  ]);

  // Instantiate the custom widget
  search.addWidgets([
    customCurrentRefinements({
      container: document.querySelector(".filter-search-dialog-current-refinements")
    })
  ]);

  const renderSearchHits = (hits, results) => {
    console.log(hits);
    return hits
      .map(
        item => `
          <div class="search-hit-item">
            <a href="${item.board_url}">
              <div class="image">
                <img alt="${item.title}" src="${item.large_thumbnail_url}">
              </div>
            </a>
            <div class="contents">
              <div class="title">
                <a href="${item.board_url}">
                  ${item.title}
                </a>
              </div>
              <div class="author">
                ${item.author_urls
            .map(
              author_url => `
                    <a href="${author_url.url}">
                      <div class="author-name">
                        ${author_url.name}
                      </div>
                    </a>`
            )
            .join("")}
              </div>
              <div class="metadata">
                ${item.is_new_book == false && item.is_yomikiri == false
            ? `
                    <div class="book-count">
                      ${!["女性マンガ誌", "男性マンガ誌"].includes(
              item.category_name
            )
              ? item.books_count !== 0
                ? `
                              <a href="${item.board_url}/books">
                                ${item.books_count}巻まで刊行
                              </a>
                            `
                : ""
              : ""
            }
                    </div>
                   `
            : ""
          }
                ${item.is_new_book
            ? `
                      <div class="board-label-tag new-series">
                        <a href="/new_series">
                          新連載
                        </a>
                      </div>
                    `
            : item.is_yomikiri
              ? `
                      <div class="board-label-tag yomikiri">
                        <a href="/yomikiri">
                          読切
                        </a>
                      </div>
                    `
              : item.is_completed_book
                ? `
                      <div class="board-label-tag completed">
                        <a href="/completed">
                          完結
                        </a>
                      </div>
                    `
                : ""
          }

              </div>
              <div class="summary">${item.first_book_summary}</div>
              ${item.has_free_campaigns
            ? `
                  <div class="action-button">
                    <a target="_blank" rel="noopener nofollow" href="${item.free_campaign_redirect_url}">
                      <div class="button free-campaign">
                        <div class="icon">
                          <img alt="book" src="https://res.cloudinary.com/hstqcxa7w/image/upload/common/icons/icon_free_campaign.svg">
                        </div>
                        <div class="text">${item.free_campaign_books_count}冊まで無料</div>
                      </div>
                    </a>
                  </div>
                `
            : item.has_preview && item.is_new_book
              ? `
                 <div class="action-button">
                    <a target="_blank" rel="noopener nofollow" href="${item.preview_redirect_url}">
                      <div class="button preview">
                        <div class="icon">
                          <img alt="book" src="https://res.cloudinary.com/hstqcxa7w/image/upload/common/icons/black/icon_book.svg">
                        </div>
                        <div class="text">作品を読む</div>
                      </div>
                    </a>
                  </div>
              `
              : item.has_preview && item.is_yomikiri
                ? `
                <div class="action-button">
                  <a target="_blank" rel="noopener nofollow" href="${item.preview_redirect_url}">
                    <div class="button preview">
                      <div class="icon">
                        <img alt="book" src="https://res.cloudinary.com/hstqcxa7w/image/upload/common/icons/black/icon_book.svg">
                      </div>
                      <div class="text">作品を読む</div>
                    </div>
                  </a>
                </div>
              `
                : item.has_preview
                  ? `
                <div class="action-button">
                  <a target="_blank" rel="noopener nofollow" href="${item.preview_redirect_url}">
                    <div class="button preview">
                      <div class="icon">
                        <img alt="book" src="https://res.cloudinary.com/hstqcxa7w/image/upload/common/icons/black/icon_book.svg">
                      </div>
                      <div class="text">試し読み</div>
                    </div>
                  </a>
                </div>
              `
                  : `
              <div class="action-button">
                <div class="button disable">
                  <div class="icon">
                    <img alt="book" src="https://res.cloudinary.com/hstqcxa7w/image/upload/common/icons/black/icon_book.svg">
                  </div>
                  <div class="text">試し読み</div>
                </div>
              </div>
            `
          }

            <div class="kindle-button-partial">
              <div class="board-kindle-button-module">
                <div class="action-button">
                  <a class="button" target="_blank" rel="noprefetch noopener nofollow" href="${item.kindle_redirect_url}">
                    <div class="icon">
                      <img alt="Amazonへ行く" aria-hidden="true" src="https://res.cloudinary.com/hstqcxa7w/image/upload/common/icons/amazon_logo.svg">
                    </div>
                    <div class="text">Amazonに行く</div>
                  </a>
                </div>
              </div>
            </div>
            </div>
          </div>
        `
      )
      .join("");
  };

  const renderFreeCampaignsHits = (hits, results) => {
    return hits
      .map(
        item => `
        <div class="free-campaigns-hit-item">
          <div class="hit-item">
            <a href="${item.board_url}">
              <div class="image">
                <img alt="${item.title}" src="${item.large_thumbnail_url}">
              </div>
            </a>
            <div class="contents">
              <a href="${item.board_url}">
                <div class="title">${item.title}</div>
              </a>
              <div class="author">
                ${item.author_urls
            .map(
              author_url => `
                    <a href="${author_url.url}">
                      <div class="author-name">
                        ${author_url.name}
                      </div>
                    </a>`
            )
            .join("")}
              </div>
              <div class="metadata">
                <a href="${item.board_url}/books">
                  <div class="volume-message">${item.free_campaign_books_count}冊</div>
                </a>
                <a href="${item.board_url}/books">
                  <div class="campaign-end-at">${item.free_campaign_end_at_text}</div>
                </a>
              </div>
            </div>
          </div>
          <div class="action-button">
            <a target="_blank" rel="noopener nofollow" href="${item.free_campaign_redirect_url
          }">
              <div class="button">
                <div class="icon">
                  <img alt="book" src="https://res.cloudinary.com/hstqcxa7w/image/upload/common/icons/icon_free_campaign.svg">
                </div>
                <div class="text">${item.free_campaign_books_count}冊まで無料</div>
              </div>
            </a>
          </div>
        </div>
      `
      )
      .join("");
  };

  const renderKindleUnlimitedHits = (hits, results) => {
    return hits
      .map(
        item => `
        <div class="kindle-unlimited-hit-item">
          <div class="hit-item">
            <a href="${item.board_url}">
              <div class="image">
                <img alt="${item.title}" src="${item.large_thumbnail_url}">
              </div>
            </a>
            <div class="contents">
              <a href="${item.board_url}">
                <div class="title">${item.title}</div>
              </a>
              <div class="author">
                ${item.author_urls
            .map(
              author_url => `
                    <a href="${author_url.url}">
                      <div class="author-name">
                        ${author_url.name}
                      </div>
                    </a>`
            )
            .join("")}
              </div>
              <div class="metadata">
                <a href="${item.board_url}/books">
                  <div class="volume-message">${item.kindle_unlimited_books_count}冊</div>
                </a>
              </div>
            </div>
          </div>
          <div class="action-button">
            <a target="_blank" rel="noopener nofollow" href="${item.kindle_redirect_url
          }">
              <div class="button">
                <div class="kindle-unlimited-banner"><div class="kindle-text">Kindle</div><div class="unlimited-text">Unlimited</div></div>
              </div>
            </a>
          </div>
        </div>
      `
      )
      .join("");
  };

  const renderItemHits = (hits, results) => {
    if (
      results._state.disjunctiveFacetsRefinements.has_free_campaigns.length ===
      1
    ) {
      return renderFreeCampaignsHits(hits, results);
    } else if (
      results._state.disjunctiveFacetsRefinements.has_kindle_unlimited
        .length === 1
    ) {
      return renderKindleUnlimitedHits(hits, results);
    } else {
      return renderSearchHits(hits, results);
    }
  };

  const renderHits = (renderOptions, isFirstRender) => {
    const { hits, results, showMore, widgetParams } = renderOptions;
    const readMoreElement = document.querySelector('.infinite-scroll-readmore-module');

    if (isFirstRender) {
      readMoreElement.style.display = 'none';
      readMoreElement.addEventListener('click', () => {
        showMore();
      });
    }

    if (results === undefined) {
      return;
    }

    if (results.nbHits <= 50) {
      readMoreElement.style.display = 'none';
    } else {
      readMoreElement.style.display = 'block';
    }

    widgetParams.container.querySelector(
      ".filter-search-hit-items"
    ).innerHTML = renderItemHits(hits, results);
  };

  // Create the custom widget
  const customHits = instantsearch.connectors.connectInfiniteHits(renderHits);

  search.addWidgets([
    customHits({
      container: document.querySelector(".filter-search-hits"),
    })
  ]);

  const renderSearchDialogHits = (hits, results) => {
    return hits
      .map(
        item => `
          <div class="search-dialog-hit-item">
            <a href="${item.board_url}">
              <div class="image">
                <img alt="${item.title}" src="${item.large_thumbnail_url}">
              </div>
            </a>
            <div class="contents">
              ${item.has_free_campaigns
            ? `
                    <div class="action-button">
                      <a target="_blank" rel="noopener nofollow" href="${item.free_campaign_redirect_url}">
                        <div class="button free-campaign">
                          <div class="icon">
                            <img alt="book" src="https://res.cloudinary.com/hstqcxa7w/image/upload/common/icons/icon_free_campaign.svg">
                          </div>
                          <div class="text">${item.free_campaign_books_count}冊まで無料</div>
                        </div>
                      </a>
                    </div>
                  `
            : item.has_preview && item.is_new_book
              ? `
                  <div class="action-button">
                      <a target="_blank" rel="noopener nofollow" href="${item.preview_redirect_url}">
                        <div class="button preview">
                          <div class="icon">
                            <img alt="book" src="https://res.cloudinary.com/hstqcxa7w/image/upload/common/icons/black/icon_book.svg">
                          </div>
                          <div class="text">作品を読む</div>
                        </div>
                      </a>
                    </div>
                `
              : item.has_preview && item.is_yomikiri
                ? `
                  <div class="action-button">
                    <a target="_blank" rel="noopener nofollow" href="${item.preview_redirect_url}">
                      <div class="button preview">
                        <div class="icon">
                          <img alt="book" src="https://res.cloudinary.com/hstqcxa7w/image/upload/common/icons/black/icon_book.svg">
                        </div>
                        <div class="text">作品を読む</div>
                      </div>
                    </a>
                  </div>
                `
                : item.has_preview
                  ? `
                  <div class="action-button">
                    <a target="_blank" rel="noopener nofollow" href="${item.preview_redirect_url}">
                      <div class="button preview">
                        <div class="icon">
                          <img alt="book" src="https://res.cloudinary.com/hstqcxa7w/image/upload/common/icons/black/icon_book.svg">
                        </div>
                        <div class="text">試し読み</div>
                      </div>
                    </a>
                  </div>
                `
                  : `
                <div class="action-button">
                  <div class="button disable">
                    <div class="icon">
                      <img alt="book" src="https://res.cloudinary.com/hstqcxa7w/image/upload/common/icons/black/icon_book.svg">
                    </div>
                    <div class="text">試し読み</div>
                  </div>
                </div>
              `
          }
            </div>
          </div>
        `
      )
      .join("");
  };


  const renderDialogItemHits = (hits, results) => {
    return renderSearchDialogHits(hits, results);
  };

  const renderDialogHits = (renderOptions, isFirstRender) => {
    const { hits, results, showMore, widgetParams } = renderOptions;

    widgetParams.container.querySelector(
      ".filter-search-dialog-hit-items"
    ).innerHTML = renderDialogItemHits(hits, results);
  }

  // Create the custom widget
  const customDialogHits = instantsearch.connectors.connectInfiniteHits(renderDialogHits);

  search.addWidgets([
    customDialogHits({
      container: document.querySelector(".filter-search-dialog-hits-partial"),
    })
  ]);

  /*
    const renderPagination = (renderOptions, isFirstRender) => {`
      const {
        pages,
        currentRefinement,
        nbPages,
        isFirstPage,
        isLastPage,
        refine,
        createURL,
        widgetParams
      } = renderOptions;

      if (isFirstRender) {
        let div = document.createElement("div");
        div.setAttribute("class", "filter-search-pagination-module");

        let div_0 = document.createElement("div");
        div_0.setAttribute("class", "popular-pagination");
        div.appendChild(div_0);

        let div_1 = document.createElement("div");
        div_1.setAttribute("class", "page-items");
        div_1.setAttribute("id", "page-items");
        div_0.appendChild(div_1);

        widgetParams.container.appendChild(div);
      }

      const container = widgetParams.container.querySelector("#page-items");

      container.innerHTML = `
        ${
          !isFirstPage
            ? `
              <a class="page-item" href="${createURL(0)}" data-value="${0}">
                <div class="link next"><<</div>
              </a>
              `
            : `
              <div class="page-item disabled"><<</div>
              `
        }
        ${pages
          .map(
            page => `
              <a class="${
                currentRefinement === page ? "page-item current" : "page-item"
              } href="${createURL(page)}" data-value="${page}" >
                <div class="link">${page + 1}</div>
              </a>
            `
          )
          .join("")}
        ${
          !isLastPage
            ? `
              <a class="page-item" href="${createURL(
                nbPages - 1
              )}" data-value="${nbPages - 1}">
                <div class="link next">>></div>
              </a>
              `
            : `
              <div class="page-item disabled">>></div>
              `
        }
      `;
      [...container.querySelectorAll("a")].forEach(element => {
        element.addEventListener("click", event => {
          event.preventDefault();
          refine(event.currentTarget.dataset.value);

          if (app.isSmartPhone()) {
            const headerHeight = 55;
            let rect = document
              .querySelector("#filters-search-box")
              .getBoundingClientRect();
            let position = rect.top + document.body.scrollTop;
            window.scrollTo({
              top: position - headerHeight
            });
          }
        });
      });
    };

    // Create the custom widget
    const customPagination = instantsearch.connectors.connectPagination(
      renderPagination
    );

    // Instantiate the custom widget
    search.addWidget(
      customPagination({
        container: document.querySelector(".filter-pagination"),
        padding: 2
      })
    );
      */
  search.start();

  function getCollapseIcon(collapsed) {
    return collapsed ? "<i class='material-icons'>expand_more</i>" : "<i class='material-icons'>expand_less</i>";
  }

  function toString(data) {
    if (data === null || data === undefined) {
      return "";
    }
    return data;
  }

  function toBoolean(data) {
    if (data === null || data === undefined) {
      return false;
    }
    return data.toLowerCase() === "true";
  }

  function toStringArray(data) {
    if (data === null || data === undefined) {
      return null;
    }
    return data.split(",");
  }

  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const params = Object.fromEntries(searchParams.entries());

  if (params !== {}) {
    // https://www.algolia.com/doc/api-reference/widgets/ui-state/js/

    const query = toString(params["q"]);
    const hasFreeCampaigns = toBoolean(params["free_campaigns"]);
    const hasSaleCampaigns = toBoolean(params["sale_campaigns"]);
    const hasKindleUnlimited = toBoolean(params["kindle_unlimited"]);
    const hasKindle = toBoolean(params["kindle"]);
    const hasWebManga = toBoolean(params["web_manga"]);
    const isNewSeries = toBoolean(params["new_series"]);
    const isYomikiri = toBoolean(params["yomikiri"]);
    const isCompleted = toBoolean(params["completed"]);
    const mediaTypeNames = toStringArray(params["media_type_names"]);
    const keywordTagNames = toStringArray(params["keyword_tag_names"]);
    const emotionalTagNames = toStringArray(params["emotional_tag_names"]);
    const awardNames = toStringArray(params["award_names"]);
    const categoryNames = toStringArray(params["category_names"]);
    const tagNames = toStringArray(params["tag_names"]);
    const releaseYearRange = toStringArray(params["release_year_range"]);
    const releaseYear = toStringArray(params["release_year"]);
    const publisherNames = toStringArray(params["publisher_names"]);
    const labelNames = toStringArray(params["label_names"]);
    const title = toString(params["title"]);
    const author = toString(params["author"]);
    let searchQuery = [query, title, author].filter(Boolean).join(' ');

    search.setUiState({
      Board_production: {
        query: searchQuery,
        toggle: {
          has_free_campaigns: hasFreeCampaigns,
          has_sale_campaigns: hasSaleCampaigns,
          has_kindle_unlimited: hasKindleUnlimited,
          has_kindle: hasKindle,
          has_web_manga: hasWebManga,
          is_new_book: isNewSeries,
          is_completed_book: isCompleted,
          is_yomikiri: isYomikiri,
        },
        refinementList: {
          media_type_names: mediaTypeNames,
          keyword_tag_names: keywordTagNames,
          emotional_tag_names: emotionalTagNames,
          award_names: awardNames,
          category_name: categoryNames,
          _tags: tagNames,
          release_year_range: releaseYearRange,
          release_year_text: releaseYear,
          publisher_name: publisherNames,
          label_name: labelNames
        },
      }
    });
  }

  const dialogButtonElement = document.querySelector(".filter-search-dialog-event");
  const dialogElement = document.querySelector(".filter-search-dialog");
  const filterSearchSearchPartialElement = document.querySelector(".filter-search-search-box-partial");
  const dialogFilterSearchSearchPartiallement = document.querySelector(".filter-search-dialog-search-box-partial");
  const filterSearchBoxModuleElement = document.querySelector(".filter-search-search-box-module");

  const dialogFilterSearchApplyElement = document.querySelector(".filter-search-apply");
  const dialogFfilterSearchCloseButtonElement = document.querySelector(".filter-search-dialog-close-button");


  dialogButtonElement.addEventListener('click', (event) => {
    dialogFilterSearchSearchPartiallement.appendChild(filterSearchBoxModuleElement)

    dialogElement.showModal();

    const focusedElement = document.activeElement;

    if (focusedElement !== null) {
      focusedElement.blur();
    }
  });

  dialogElement.addEventListener('click', (event) => {
    if (event.target.closest('.filter-search-dialog-contents') === null) {
      //filterSearchSearchPartialElement.appendChild(filterSearchBoxModuleElement)
      // dialogElement.close();
    }
  });

  let dialogFilterStatsButtonElement = document.querySelector(".filters-stats-button");
  dialogFilterStatsButtonElement.addEventListener('click', (event) => {

    filterSearchSearchPartialElement.appendChild(filterSearchBoxModuleElement)

    dialogElement.close();
  });


  dialogFilterSearchApplyElement.addEventListener('click', (event) => {
    closeDialog();
  });

  dialogFfilterSearchCloseButtonElement.addEventListener('click', (event) => {
    closeDialog();
  });

  function closeDialog() {
    filterSearchSearchPartialElement.appendChild(filterSearchBoxModuleElement)

    dialogElement.close();

  }
});
