import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  pubsubStore.subscribe("userLoginCompleted", args => {
    if (loginStateStore.isLoggedIn() == true) {
      document.querySelectorAll(".manba-logged-in-display").forEach(element => {
        element.style.display = "block";
      });
      document.querySelectorAll(".manba-logged-out-display").forEach(element => {
        element.remove();
      });
    } else {
      document.querySelectorAll(".manba-logged-in-display").forEach(element => {
        element.remove();
      });
      document.querySelectorAll(".manba-logged-out-display").forEach(element => {
        element.style.display = "block";
      });
    }
  });
});
